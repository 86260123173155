body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Bulgatti";
  src: local("Bulgatti"),
   url('./fonts/brannboll-connect-personal-use/BrannbollConnect_PERSONAL_USE.ttf') format("truetype");
  font-weight: bold;
 }
 @font-face {
  font-family: "Brannboll";
  src: local("Brannboll"),
   url('./fonts/bulgatti-font/Bulgatti-xgMV.ttf') format("truetype");
  font-weight: bold;
 }

 @font-face {
  font-family: "Daragie-Regular";
  src: local("Daragie-Regular"),
   url('./fonts/daragie-regular/Daragie-Regular/Daragie-Regular.otf') format("opentype");
  font-weight: bold;
 }